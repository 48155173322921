<template>
    <div class="content_area">
        <div class="content_wpr">
            <div class="section_content w-100" v-if="invoiceLoader">
                <quote-loader />
            </div>
            <template v-else>
                <div class="section_content w-100" v-if="user.stripe_id">
                    <div class="section_header">
                        <h2>Billing History<span>Access all your previous invoices.</span></h2>
                    </div>
                    <div class="plan_container">
                        <div class="billing_history">
                            <div class="result_wpr">
                                <div class="table_title">Invoices</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Invoice</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            <th>Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(invoice, i) of invoices.data" :key="i">
                                            <td>{{ invoice.invoice_number }}</td>
                                            <td>{{ invoice.invoice_date }}</td>
                                            <td>
                                                <p>${{ invoice.invoice_total }}</p>
                                            </td>
                                            <td>
                                                <span :class="`status invoice-${invoice.invoice_status}`">
                                                    <i class="fas fa-eye" v-if="invoice.invoice_status == 'open'"></i>
                                                    <i class="fas fa-pencil-alt" v-else-if="invoice.invoice_status == 'draft'"></i>
                                                    <i class="fas fa-check" v-else-if="invoice.invoice_status == 'paid'"></i>
                                                    {{ invoice.invoice_status }}
                                                </span>
                                            </td>
                                            <td>
                                                <i class="fas fa-download" @click="downloadInvoice(i)"></i>
                                                <i class="far fa-file-alt" @click="selectInvoice(i)"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="pagination pb-4 mt-4">
                                <pagination v-model="params.page" :pages="invoices.last_page" :range-size="0" @update:modelValue="handlePagination" />
                            </div>
                        </div>
                        <div class="invoice_container" style="margin-top:20px;width:450px;background:#fff;border-radius:8px;box-shadow:0 4px 20px rgba(0,0,0,0.05);overflow:hidden;">
                            <div style="display:flex;justify-content:flex-start;border-bottom:1px solid #eee;gap:12px;padding:20px 30px;">
                                <img style="height:35px;width:auto;" src="@/assets/images/logo.svg">
                                <div>
                                    <h4 style="font-size:15px;line-height:20px;color:#121525;font-weight:500;">Thrive System Inc.</h4>
                                    <h6 style="font-size:9px;line-height:12px;color:#5a5a5a;font-weight:500;margin-top:3px;">Colorado Springs, CO</h6>
                                </div>
                            </div>
                            <div style="padding:25px 30px;">
                                <ul style="display:flex;gap:30px;margin-bottom:25px;justify-content: space-between;">
                                    <li>
                                        <h6 style="font-size:11px;line-height:13px;color:#777;font-weight:500;">Invoice Number:</h6>
                                        <p style="font-size:11px;line-height:14px;color:#121525;font-weight:500;margin-top:5px;">{{ selectedInvoice.invoice_number }}</p>
                                    </li>
                                    <li>
                                        <h6 style="font-size:11px;line-height:13px;color:#777;font-weight:500;">Date of Issue:</h6>
                                        <p style="font-size:11px;line-height:14px;color:#121525;font-weight:500;margin-top:5px;">{{ selectedInvoice.invoice_date }}</p>
                                    </li>
                                </ul>
                                <ul style="display:flex;gap:15px;">
                                    <li>
                                        <h6 style="font-size:11px;line-height:13px;color:#777;font-weight:500;">Billed To:</h6>
                                        <p style="font-size:11px;line-height:14px;color:#121525;font-weight:500;margin-top:5px;">
                                            {{ selectedInvoice.customer_name }} ({{ selectedInvoice.customer_email }})<br/>
                                            <template v-if="selectedInvoice.customer_address">
                                                {{ selectedInvoice.customer_address.line1 }} {{ selectedInvoice.customer_address.line2 }}, {{ selectedInvoice.customer_address.city }}, {{ selectedInvoice.customer_address.state }}, {{ selectedInvoice.customer_address.country }}
                                            </template>
                                        </p>
                                    </li>
                                </ul>
                                <table style="border:1px solid #f1f1f1;border-collapse:collapse;margin-top:25px;border-spacing:0;width:100%;">
                                    <thead style="background:#eee;">
                                        <tr>
                                            <th style="font-size:9px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">Description</th>
                                            <th style="font-size:9px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">Qty</th>
                                            <th style="font-size:9px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, li) of selectedInvoice.line_items" :key="li">
                                            <td style="font-size:10px;line-height:13px;color:#121525;font-weight:500;padding:10px;">
                                                {{ item.description }}
                                            </td>
                                            <td style="font-size:10px;line-height:13px;color:#121525;font-weight:500;padding:10px;">
                                                {{ item.quantity }}
                                            </td>
                                            <td style="font-size:10px;line-height:13px;color:#121525;font-weight:500;padding:10px;">
                                                ${{ item.amount }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style="display:flex;justify-content:space-between;align-items:flex-start;margin-top:20px;">
                                    <div>
                                        <h6 style="font-size:11px;line-height:13px;color:#777;font-weight:500;">Invoice Total</h6>
                                        <h2 style="font-size:25px;line-height:30px;color:#2f7eed;font-weight:400;margin-top:5px;">${{ selectedInvoice.invoice_total }}</h2>
                                    </div>
                                    <table style="width:200px;">
                                        <tr>
                                            <th align="right" style="font-size:11px;line-height:13px;color:#2f7eed;font-weight:500;padding:6px 10px;">Subtotal</th>
                                            <td style="font-size:11px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">${{ selectedInvoice.invoice_subtotal }}</td>
                                        </tr>
                                        <tr v-if="selectedInvoice.discount && selectedInvoice.discount.amount">
                                            <th align="right" style="font-size:11px;line-height:13px;color:#2f7eed;font-weight:500;padding:6px 10px;">
                                                {{ selectedInvoice.discount.coupon }} {{ selectedInvoice.discount.percent_off ? `(${selectedInvoice.discount.percent_off}% off)` : `($${selectedInvoice.discount.amount_off} off)` }}
                                            </th>
                                            <td style="font-size:11px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">
                                                ${{ selectedInvoice.discount.amount }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th align="right" style="font-size:11px;line-height:13px;color:#2f7eed;font-weight:500;padding:6px 10px;">Tax</th>
                                            <td style="font-size:11px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">$0.00</td>
                                        </tr>
                                        <tr>
                                            <th align="right" style="font-size:11px;line-height:13px;color:#2f7eed;font-weight:500;padding:6px 10px;">Total</th>
                                            <td style="font-size:11px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">${{ selectedInvoice.invoice_total }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_content w-100" v-else>
                    <div class="migrate-to-thrive">
                        <h3 class="mb-3">Your account is a legacy account and your billing is the lowest rate we offer. If you need to update your billing please email <a href="mailto:support@thrivecoach.io">support@thrivecoach.io</a></h3>
                        <p class="mb-5">If you wish to migrate to Thrive Coach billing you can click the Migrate button. WARNING: Doing so will disable your Onboard Me account permanently.</p>
                        <a class="btn pull-right" href="mailto:support@thrivecoach.io">Migrate to Thrive Coach</a>
                    </div>
                </div>
            </template>
        </div>

        <vue3-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            :filename="invoiceFileName"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="invoice-html2Pdf"
        >
            <template v-slot:pdf-content>
                <div style="margin-top:20px;background:#fff;border-radius:8px;box-shadow:0 4px 20px rgba(0,0,0,0.05);overflow:hidden;">
                    <div style="display:flex;justify-content:flex-start;border-bottom:1px solid #eee;gap:12px;padding:20px 30px;">
                        <img eager style="height:35px; width:auto;" src="@/assets/images/logo-35x35.png">
                        <div style="text-align:left;">
                            <h4 style="font-size:15px;line-height:20px;color:#121525;font-weight:500;">Thrive System Inc.</h4>
                            <h6 style="font-size:9px;line-height:12px;color:#5a5a5a;font-weight:500;margin-top:3px;">Colorado Springs, CO</h6>
                        </div>
                    </div>
                    <div style="padding:25px 30px;">
                        <ul style="display:flex;gap:30px;margin-bottom:25px;justify-content: space-between;">
                            <li>
                                <h6 style="font-size:11px;line-height:13px;color:#777;font-weight:500;">Invoice Number:</h6>
                                <p style="font-size:11px;line-height:14px;color:#121525;font-weight:500;margin-top:5px;">{{ selectedInvoice.invoice_number }}</p>
                            </li>
                            <li>
                                <h6 style="font-size:11px;line-height:13px;color:#777;font-weight:500;">Date of Issue:</h6>
                                <p style="font-size:11px;line-height:14px;color:#121525;font-weight:500;margin-top:5px;">{{ selectedInvoice.invoice_date }}</p>
                            </li>
                        </ul>
                        <ul style="display:flex;gap:15px;">
                            <li>
                                <h6 style="font-size:11px;line-height:13px;color:#777;font-weight:500;text-align:left;">Billed To:</h6>
                                <p style="font-size:11px;line-height:14px;color:#121525;font-weight:500;margin-top:5px;text-align:left;">
                                    {{ selectedInvoice.customer_name }} ({{ selectedInvoice.customer_email }})<br/>
                                    <template v-if="selectedInvoice.customer_address">
                                        {{ selectedInvoice.customer_address.line1 }} {{ selectedInvoice.customer_address.line2 }}, {{ selectedInvoice.customer_address.city }}, {{ selectedInvoice.customer_address.state }}, {{ selectedInvoice.customer_address.country }}
                                    </template>
                                </p>
                            </li>
                        </ul>
                        <table style="border:1px solid #f1f1f1;border-collapse:collapse;margin-top:25px;border-spacing:0;width:100%;">
                            <thead style="background:#eee;">
                                <tr>
                                    <th style="font-size:9px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;text-align:left;">Description</th>
                                    <th style="font-size:9px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">Qty</th>
                                    <th style="font-size:9px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, li) of selectedInvoice.line_items" :key="li">
                                    <td style="font-size:10px;line-height:13px;color:#121525;font-weight:500;padding:10px;text-align:left;">
                                        {{ item.description }}
                                    </td>
                                    <td style="font-size:10px;line-height:13px;color:#121525;font-weight:500;padding:10px;">
                                        {{ item.quantity }}
                                    </td>
                                    <td style="font-size:10px;line-height:13px;color:#121525;font-weight:500;padding:10px;">
                                        ${{ item.amount }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="display:flex;justify-content:space-between;align-items:flex-start;margin-top:20px;">
                            <div>
                                <h6 style="font-size:11px;line-height:13px;color:#777;font-weight:500;">Invoice Total</h6>
                                <h2 style="font-size:25px;line-height:30px;color:#2f7eed;font-weight:400;margin-top:5px;">${{ selectedInvoice.invoice_total }}</h2>
                            </div>
                            <table style="width:200px;">
                                <tr>
                                    <th align="right" style="font-size:11px;line-height:13px;color:#2f7eed;font-weight:500;padding:6px 10px;">Subtotal</th>
                                    <td style="font-size:11px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">${{ selectedInvoice.invoice_subtotal }}</td>
                                </tr>
                                <tr v-if="selectedInvoice.discount && selectedInvoice.discount.amount">
                                    <th align="right" style="font-size:11px;line-height:13px;color:#2f7eed;font-weight:500;padding:6px 10px;">
                                        {{ selectedInvoice.discount.coupon }} {{ selectedInvoice.discount.percent_off ? `(${selectedInvoice.discount.percent_off}% off)` : `($${selectedInvoice.discount.amount_off} off)` }}
                                    </th>
                                    <td style="font-size:11px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">
                                        ${{ selectedInvoice.discount.amount }}
                                    </td>
                                </tr>
                                <tr>
                                    <th align="right" style="font-size:11px;line-height:13px;color:#2f7eed;font-weight:500;padding:6px 10px;">Tax</th>
                                    <td style="font-size:11px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">$0.00</td>
                                </tr>
                                <tr>
                                    <th align="right" style="font-size:11px;line-height:13px;color:#2f7eed;font-weight:500;padding:6px 10px;">Total</th>
                                    <td style="font-size:11px;line-height:13px;color:#121525;font-weight:500;padding:6px 10px;">${{ selectedInvoice.invoice_total }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
        </vue3-html2pdf>
    </div>
</template>


<script>
    import axios from 'axios'
    import Toastr from '@/utils/Toastr'
    import Pagination from '@hennge/vue3-pagination'
    import Vue3Html2pdf from 'vue3-html2pdf'

    import { mapState } from 'vuex'

    export default {
        name: 'Billing History',

        data () {
            return {
                invoices: [],
                billingApi: process.env.VUE_APP_BILLING_API_URL,
                invoiceLoader: false,
                selectedInvoice: {},
                params: {
                    page: 1,
                },
                invoiceFileName: '',
            };
        },

        components: {
            Pagination,
            Vue3Html2pdf
        },

        computed: mapState({
            user: state => state.authModule.user,
        }),

        mounted () {
            const vm = this;

            vm.getInvoices();
        },

        methods: {
            getInvoices (page = 1) {
                const vm = this;

                vm.invoiceLoader = true;

                axios.get(`${vm.billingApi}/stripe/invoices`, {
                    params: { page },
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {
                    vm.invoiceLoader = false;
                    vm.invoices = resp.data;

                    vm.selectInvoice(0);
                }).catch((err) => {
                    vm.invoiceLoader = false;
                });
            },

            selectInvoice (key) {
                const vm = this;

                if (vm.invoices.data && vm.invoices.data[key]) {
                    vm.selectedInvoice = vm.invoices.data[key];
                } else {
                    vm.selectedInvoice = {};
                }
            },

            downloadInvoice (key) {
                const vm = this;

                if (vm.invoices.data && vm.invoices.data[key]) {
                    const invoice = vm.invoices.data[key];

                    vm.selectedInvoice = invoice;
                    vm.invoiceFileName = invoice.invoice_number;

                    setTimeout(function () {
                        vm.$refs['invoice-html2Pdf'].generatePdf();
                    }, 100);
                } else {

                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getInvoices(page);
            },
        },
    }
</script>

<style scoped>
    .section_content .quote_wpr {
        min-height: 90vh;
        justify-content: center;
    }

    .migrate-to-thrive {
        display: flex;
        height: 90vh;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0px 50px;
        text-align: center;
    }

    .migrate-to-thrive a.btn {
        font-size: 12px;
        line-height: 15px;
        background: #2f7eed;
        text-transform: uppercase;
        color: #fff;
        font-weight: 500;
        padding: 10px 25px;
        border-radius: 20px;
        cursor: pointer;
        font-family: 'Inter', sans-serif;
        text-decoration: none;
    }

    .migrate-to-thrive a {
        text-decoration: none;
        color: #2f7eed;
    }

    .billing_history .result_wpr table td .status.invoice-open {
        background: rgb(220, 237, 255);
    }

    .billing_history .result_wpr table td .status.invoice-draft {
        background: rgb(238, 238, 238);
    }
</style>
